import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "../components/NavbarComponent";
import FooterComponent from "../components/FooterComponent";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false); // State for checkbox

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      alert("You must agree to subscribe to TechieGPT for $10 per month.");
      return;
    }
    
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/userdetails/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            items: [{ id: 1, quantity: 1 }],
          }),
        }
      );

      if (response.ok) {
        const { url } = await response.json();
        window.location.href = url;
      } else {
        const error = await response.json();
        console.log(error);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const containerStyle = {
    padding: "40px",
    textAlign: "center",
    backgroundColor: "#f9f9f9",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const headingStyle = {
    fontSize: "2.5rem",
    marginBottom: "20px",
    color: "#333",
  };

  const paragraphStyle = {
    fontSize: "1.2rem",
    marginBottom: "30px",
    color: "#555",
  };

  const buttonStyle = {
    padding: "12px 24px",
    fontSize: "18px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };

  return (
    <div>
      <NavbarComponent />
      <div style={containerStyle}>
        <h1 style={headingStyle}>Subscribe to TechieGPT!</h1>
        <p style={paragraphStyle}>
          You are about to subscribe to <strong>TechieGPT</strong>, an advanced tech support AI.
          For just <strong>$10 per month</strong> (including taxes), you will receive expert assistance and support, billed monthly.
        </p>
        <div style={{ marginBottom: "20px" }}>
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
            I agree to subscribe to TechieGPT for $10 per month (including taxes)
          </label>
        </div>
        <button onClick={handleSubmit} style={buttonStyle}>
          Proceed to Checkout
        </button>
      </div>
      <FooterComponent />
    </div>
  );
};

export default CheckoutPage;
