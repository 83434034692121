import FooterComponent from "../components/FooterComponent";
import NavbarComponent from "../components/NavbarComponent";

const ContactPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <NavbarComponent />
      <div style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
      }}>
        <div style={{
          maxWidth: '600px',
          padding: '20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
        }}>
          <h1 style={{ color: '#333' }}>Contact Us</h1>
          <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
            Thank you for your interest in Techie GPT! 
            <br />
            <strong>Work in Progress</strong>
          </p>
          <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
            We're currently updating our contact features to serve you better.
            Please check back soon for updates!
          </p>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export default ContactPage;
