import React from 'react';

const TermsAndConditions = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '20px auto',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    heading: {
      color: '#333',
      marginBottom: '10px',
    },
    paragraph: {
      lineHeight: '1.6',
      color: '#555',
      marginBottom: '10px',
    },
    list: {
      marginLeft: '20px',
      marginBottom: '10px',
    },
    footer: {
      marginTop: '20px',
      padding: '10px 0',
      borderTop: '1px solid #ccc',
      textAlign: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Terms and Conditions</h1>

      <h2 style={styles.heading}>1. Acceptance of Terms</h2>
      <p style={styles.paragraph}>
        By accessing or using our services, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use our services.
      </p>

      <h2 style={styles.heading}>2. Services</h2>
      <p style={styles.paragraph}>
        Our service provides technical support through the OpenAI API. You may use our services only for lawful purposes and in accordance with these Terms.
      </p>

      <h2 style={styles.heading}>3. Payments</h2>
      <p style={styles.paragraph}>
        All payments for services are processed through Stripe. By providing your payment information, you agree to comply with Stripe's Terms of Service. We are not responsible for any payment processing issues that arise.
      </p>

      <h2 style={styles.heading}>4. User Responsibilities</h2>
      <p style={styles.paragraph}>
        You agree to use the services in compliance with all applicable laws and regulations. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
      </p>

      <h2 style={styles.heading}>5. Cancellation and Refunds</h2>
      <p style={styles.paragraph}>
        Please refer to our Cancellation Policy for information regarding the cancellation of services and refund eligibility.
      </p>

      <h2 style={styles.heading}>6. Limitation of Liability</h2>
      <p style={styles.paragraph}>
        In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our services.
      </p>

      <h2 style={styles.heading}>7. Changes to Terms</h2>
      <p style={styles.paragraph}>
        We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting on this page. Your continued use of the services after changes are posted constitutes your acceptance of the new Terms.
      </p>

      <h2 style={styles.heading}>8. Contact Us</h2>
      <p style={styles.paragraph}>
        If you have any questions about these Terms, please contact us at: tomopas602@gmail.com
      </p>

      <div style={styles.footer}>
        <p>Last updated: 09.10.2024</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
