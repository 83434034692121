import "./App.css";
import { Route, Routes } from 'react-router-dom';
import { useEffect } from "react";

import Home from "./routes/Home";
import User from "./routes/User";
import Register from "./routes/Register.js";
import ErrorPage from "./routes/ErrorPage";
import ChatPage from "./routes/ChatPage";
import Login from "./routes/Login"
import AuthenticatePage from "./routes/AuthenticatePage";
import PricingPage from "./routes/PricingPage";
import ContactPage from "./routes/ContactPage";
import ImpressumPage from "./routes/ImpressumPage";
import DataPage from "./routes/DataPage";
import Withdrawal from "./components/Withdrawal"
import TermsAndConditions from "./components/TermsAndConditions";
import CheckoutPage from "./routes/CheckoutPage";
import CancelPage from "./routes/CancelPage";
import SuccessPage from "./routes/SuccessPage";

function App() {
  useEffect(() => {
    document.title = "Techie GPT"; // Set a global title here
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/user" element={<User/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path="/chat" element={<ChatPage/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/authenticate" element={<AuthenticatePage/>}/>
        <Route path="/pricing" element={<PricingPage/>}/>
        <Route path="/contact" element={<ContactPage/>}/> 
        <Route path="/impressum" element={<ImpressumPage/>}/> 
        <Route path="/privacy-policy" element={<DataPage/>}/>
        <Route path="/withdrawal" element={<Withdrawal/>}/>
        <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
        <Route path="/checkout" element={<CheckoutPage/>}/>
        <Route path="/cancel-page" element={<CancelPage/>}/>
        <Route path="/success-page" element={<SuccessPage/>}/>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
