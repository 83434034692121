import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout'; // Import the Logout icon
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const NavbarComponent = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogout = () => {
    localStorage.removeItem("token"); // Clear token from local storage
    navigate('/login'); // Redirect to login page
  };

  return (
    <Navbar expand="lg" className="bg-body-secondary">
      <Container>
        <Navbar.Brand href="/">Techie GPT</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="authenticate">Chat</Nav.Link>
            <Nav.Link href="pricing">Pricing</Nav.Link>
            <Nav.Link href="contact">Contact</Nav.Link>
            <Nav.Link href="impressum">Impressum</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        {/* Account Icon */}
        <IconButton href="/user" color="inherit">
          <AccountCircleIcon />
        </IconButton>
        {/* Logout Button */}
        <IconButton color="inherit" onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
