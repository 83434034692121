import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const AuthenticatePage = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

        if(!token){
            navigate("/login")
        }

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/userdetails/authenticate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const json = await response.json();

      if (!response.ok) {
        setError(json.error);
        navigate("/login")
      }
      if (response.ok) {
        setError(null);
        console.log("Logged in");
        navigate("/chat")
      }
    };
    fetchData()
  }, []);

  // Handling different states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Protected Data</h1>
      <pre>{JSON.stringify(data, null, 2)}</pre> {/* Render the data */}
    </div>
  );
};

export default AuthenticatePage;
