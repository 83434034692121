import FooterComponent from "../components/FooterComponent";
import NavbarComponent from "../components/NavbarComponent";

const ImpressumPage = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <NavbarComponent />
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          textAlign: "center",
          backgroundColor: "#f9f9f9",
        }}
      >
        <div
          style={{
            maxWidth: "600px",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
          }}
        >
          <h1 style={{ color: "#333" }}>Impressum</h1>
          <h2 style={{ color: "#007bff" }}>Owner Information:</h2>
          <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
            Owner: <strong>Leonardo Balardini</strong>
          </p>
          <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
            Adress: <strong>Beethovenstrasse 24, 69221, Dossenheim</strong>
          </p>
          <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
            Email Adress: <strong>tomopas602@gmail.com</strong>
          </p>
          <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
            Telephone: <strong>+49 1520 9149 573</strong>
          </p>
          <h2 style={{ color: "#007bff" }}>Disclaimer:</h2>
          <p style={{ fontSize: "18px", lineHeight: "1.6" }}>
            The information provided by this AI is for general informational
            purposes only and should not be considered professional advice.
            While we strive for accuracy, the content may not reflect the most
            current legal, medical, or other professional standards. Always
            consult with a qualified professional for specific guidance related
            to your situation. Use of this AI is at your own risk, and we are
            not liable for any decisions made based on the information provided.
          </p>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export default ImpressumPage;
