
const CancellationPolicy = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '20px auto',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    heading: {
      color: '#333',
      marginBottom: '10px',
    },
    paragraph: {
      lineHeight: '1.6',
      color: '#555',
      marginBottom: '10px',
    },
    list: {
      marginLeft: '20px',
      marginBottom: '10px',
    },
    footer: {
      marginTop: '20px',
      padding: '10px 0',
      borderTop: '1px solid #ccc',
      textAlign: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Cancellation Policy</h1>

      <h2 style={styles.heading}>Right of Withdrawal</h2>
      <p style={styles.paragraph}>
        You have the right to withdraw from this contract within 14 days without giving any reason. The withdrawal period will expire after 14 days from the day of the conclusion of the contract.
      </p>

      <p style={styles.paragraph}>
        To exercise the right of withdrawal, you must inform us (insert your company name, address, email, and phone number) of your decision to withdraw from this contract by a clear statement (e.g., a letter sent by post, fax, or email). You may use the attached withdrawal form, but it is not obligatory.
      </p>

      <h2 style={styles.heading}>Effects of Withdrawal</h2>
      <p style={styles.paragraph}>
        If you withdraw from this contract, we shall reimburse you all payments received from you, including delivery costs (with the exception of the additional costs arising from your choice of a type of delivery other than the least expensive standard delivery offered by us), without undue delay and in any event no later than 14 days from the day on which we receive your notification of your withdrawal from this contract. We will carry out such reimbursement using the same means of payment as you used for the initial transaction, unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of such reimbursement.
      </p>

      <h2 style={styles.heading}>Special Note for Used Services</h2>
      <p style={styles.paragraph}>
        If you have already accessed or used the API services, please note that the right of withdrawal may be limited. In such cases, you may not be eligible for a full refund for the services used. Please contact us directly to discuss your situation and any possible resolutions.
      </p>

      <h2 style={styles.heading}>Withdrawal Form (Sample)</h2>
      <p style={styles.paragraph}>
        To: Techie GPT <br />
        tomopas602@gmail.com
      </p>
      <p style={styles.paragraph}>
        I/We (*) hereby withdraw from the contract concluded by me/us (*) for the purchase of the following goods (*)/provision of the following service (*): <br />
        __________________________________________________ <br />
        Ordered on (*)/received on (*): <br />
        __________________________________________________ <br />
        Name of consumer(s): <br />
        __________________________________________________ <br />
        Address of consumer(s): <br />
        __________________________________________________ <br />
        Signature of consumer(s) (only if notified on paper): <br />
        __________________________________________________ <br />
        Date: <br />
        __________________________________________________
      </p>
      <p style={styles.paragraph}>
        (*) Delete as appropriate.
      </p>

      <div style={styles.footer}>
        <p>For any questions, please contact us at: tomopas602</p>
      </div>
    </div>
  );
};

export default CancellationPolicy;
