const FooterComponent = () => {
  return (
    <footer
      style={{
        textAlign: "center",
        padding: "20px",
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "50px",
        backgroundColor: "#f1f1f1",

        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '10px 0',
        textAlign: 'center',
        backgroundColor: '#f1f1f1',
        borderTop: '1px solid #ccc',
      }}
    >
      <p>&copy; 2024 Techie GPT. All rights reserved. <a href="/privacy-policy">Privacy Policy</a></p>
    </footer>
  );
};

export default FooterComponent
