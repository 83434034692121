import NavbarComponent from "../components/NavbarComponent";
import FooterComponent from "../components/FooterComponent";

const DataPage = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh', // Ensure the container takes full height
    },
    content: {
      flex: '1', // Allows the content area to grow and fill the space
      maxWidth: '800px',
      margin: '20px auto',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    heading: {
      color: '#333',
    },
    subheading: {
      color: '#444',
      marginTop: '20px',
    },
    paragraph: {
      lineHeight: '1.6',
      color: '#555',
    },
    list: {
      marginLeft: '20px',
    }
      
  };

  return (
    <div style={styles.container}>
      <NavbarComponent />
      <div style={styles.content}>
        <h1 style={styles.heading}>Privacy Policy</h1>
        <p style={styles.paragraph}><strong>Effective Date:</strong> 09.10.2024</p>
        
        <section>
          <h2 style={styles.subheading}>1. Information We Collect</h2>
          <p style={styles.paragraph}>We may collect the following types of information:</p>
          <ul style={styles.list}>
            <li><strong>Personal Information:</strong> When you register or use our services, we may collect personal information such as your name, email address, and any other details you provide.</li>
            <li><strong>Authentication Information:</strong> We use JSON Web Tokens (JWT) for user authentication. When you log in, a JWT is generated and stored in your browser, which allows us to verify your identity during your session.</li>
            <li><strong>Payment Information:</strong> When you make a purchase, we collect payment information, including your credit card details and billing address. This information is processed securely by Stripe, and we do not store your credit card information on our servers.</li>
            <li><strong>Technical Data:</strong> We may collect technical information about your device, including your IP address, browser type, operating system, and other usage data.</li>
          </ul>
        </section>

        <section>
          <h2 style={styles.subheading}>2. How We Use Your Information</h2>
          <p style={styles.paragraph}>We may use the information we collect for various purposes, including:</p>
          <ul style={styles.list}>
            <li>To authenticate your identity and manage your user account.</li>
            <li>To process payments through Stripe and provide receipts.</li>
            <li>To provide tech support services through the OpenAI API.</li>
            <li>To improve our website and services.</li>
            <li>To communicate with you about your account and services.</li>
          </ul>
        </section>

        <section>
          <h2 style={styles.subheading}>3. Sharing Your Information</h2>
          <p style={styles.paragraph}>We do not sell or rent your personal information to third parties. However, we may share your information with:</p>
          <ul style={styles.list}>
            <li><strong>Service Providers:</strong> We may employ third-party companies and individuals (such as OpenAI and Stripe) to facilitate our services, provide support, or perform services on our behalf. These third parties will only have access to your personal information to perform these tasks and are obligated not to disclose or use it for any other purpose.</li>
          </ul>
        </section>

        <section>
          <h2 style={styles.subheading}>4. Data Security</h2>
          <p style={styles.paragraph}>We take data security seriously and use industry-standard measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. Stripe is responsible for securely processing your payment information. However, no method of transmission over the Internet or method of electronic storage is 100% secure.</p>
        </section>

        <section>
          <h2 style={styles.subheading}>5. Your Rights</h2>
          <p style={styles.paragraph}>Depending on your location, you may have the following rights regarding your personal information:</p>
          <ul style={styles.list}>
            <li>The right to access, correct, or delete your personal information.</li>
            <li>The right to object to or restrict the processing of your data.</li>
            <li>The right to withdraw consent at any time, where we rely on your consent to process your personal information.</li>
          </ul>
          <p style={styles.paragraph}>To exercise these rights, please contact us at [Your Contact Email].</p>
        </section>

        <section>
          <h2 style={styles.subheading}>6. Changes to This Privacy Policy</h2>
          <p style={styles.paragraph}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date at the top. We encourage you to review this Privacy Policy periodically for any changes.</p>
        </section>

        <section>
          <h2 style={styles.subheading}>7. Contact Us</h2>
          <p style={styles.paragraph}>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
          <p style={styles.paragraph}>
            email<br />
            +49 1520 9419 573
          </p>
        </section>
      </div>
      <footer style={styles.footer}>
        <FooterComponent />
      </footer>
    </div>
  );
};

export default DataPage;
