import FooterComponent from "../components/FooterComponent";
import NavbarComponent from "../components/NavbarComponent";

const ErrorPage = () => {
  return (
    <div>
      <NavbarComponent />
      <h3>404 Page not found</h3>
      <FooterComponent />
    </div>
  );
};

export default ErrorPage;
