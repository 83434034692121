import { io } from "socket.io-client";
import { useState, useEffect } from "react";
import NavbarComponent from "../components/NavbarComponent";
import FooterComponent from "../components/FooterComponent";

const ChatPage = () => {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([
    {
      user: "Techie GPT",
      message: "Hello. How can I help you?",
    },
  ]);

  // send message
  const handleSubmit = async (e) => {
    e.preventDefault();
    const socket = io(process.env.REACT_APP_SOCKET_IO_URL);

    if (
      message !== "" &&
      chatHistory[chatHistory.length - 1].user == "Techie GPT"
    ) {
      // authentication
      const token = localStorage.getItem("token");
      const messageShow = { user: "User", message: message };
      setChatHistory((prevChatHistory) => [...prevChatHistory, messageShow]);
      const messageSent = {
        user: "User",
        message: message,
        token: `Bearer ${token}`,
      };
      socket.emit("send", messageSent);
      setMessage("");
      console.log("message sent");
      // Set up the socket listener for receiving messages
      const handleReceiveMessage = (message) => {
        console.log(message);
        setChatHistory((prevChatHistory) => [...prevChatHistory, message]);
        socket.off("receive", handleReceiveMessage); // Remove the listener
        socket.disconnect(); // Disconnect the socket
      };

      socket.on("receive", handleReceiveMessage);
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <NavbarComponent />
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          backgroundColor: "#f0f0f0", // Light background for the page
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "600px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#ffffff", // White background for chat box
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            background: "linear-gradient(180deg, #e3f2fd 0%, #bbdefb 100%)", // Gradient background
          }}
        >
          <h3 style={{ color: "#333", marginBottom: "20px" }}>
            Chat with Tech Support AI
          </h3>
          <ul
            style={{
              listStyleType: "none",
              padding: 0,
              margin: "0 0 20px 0",
              overflowY: "auto",
              maxHeight: "300px",
            }}
          >
            {chatHistory.map((element, index) => (
              <li
                key={index}
                style={{
                  marginBottom: "10px",
                  textAlign: element.user === "User" ? "right" : "left",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    padding: "10px",
                    borderRadius: "8px",
                    maxWidth: "75%",
                    margin:
                      element.user === "User" ? "0 0 0 auto" : "0 auto 0 0",
                    backgroundColor:
                      element.user === "User" ? "#dcf8c6" : "#e0e0e0",
                    color: "#333",
                  }}
                >
                  <strong>{element.user}:</strong> {element.message}
                </div>
              </li>
            ))}
          </ul>

          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              type="text"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              style={{
                flex: 1,
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                marginRight: "10px",
              }}
              placeholder="Type your message..."
            />
            <button
              type="submit"
              style={{
                padding: "10px 15px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                transition: "background-color 0.3s",
              }}
            >
              Send
            </button>
          </form>
        </div>
      </div>
      <FooterComponent/>
    </div>
  );
};

export default ChatPage;
