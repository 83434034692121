import React from 'react';
import NavbarComponent from "../components/NavbarComponent";
import FooterComponent from "../components/FooterComponent";
import { Link } from 'react-router-dom'; // Assuming you are using React Router for navigation

const SuccessPage = () => {
    return (
        <div style={styles.container}>
            <NavbarComponent />
            <div style={styles.content}>
                <h1 style={styles.title}>Subscription Successful!</h1>
                <p style={styles.message}>
                    Congratulations! You have successfully subscribed to TechieGPT.
                </p>
                <p style={styles.info}>
                    This subscription is billed monthly and can be canceled anytime under your account settings (icon at the top right of the website).
                </p>
                <Link to="/user" style={styles.button}>
                    Go to Account
                </Link>
            </div>
            <FooterComponent />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f8f9fa',
        color: '#343a40',
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        textAlign: 'center',
    },
    title: {
        color: '#28a745', // Green for success
    },
    message: {
        margin: '20px 0',
    },
    info: {
        margin: '20px 0',
        fontStyle: 'italic',
    },
    button: {
        display: 'inline-block',
        padding: '10px 20px',
        color: 'white',
        backgroundColor: '#007bff', // Blue
        textDecoration: 'none',
        borderRadius: '5px',
        transition: 'background-color 0.3s',
    },
};

export default SuccessPage;
