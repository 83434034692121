import React from 'react';
import NavbarComponent from "../components/NavbarComponent";
import FooterComponent from "../components/FooterComponent";
import { Link } from 'react-router-dom'; // Assuming you are using React Router for navigation

const CancelPage = () => {
    return (
        <div style={styles.container}>
            <NavbarComponent />
            <div style={styles.content}>
                <h1 style={styles.title}>Payment Failed</h1>
                <p style={styles.message}>
                    We're sorry, but your payment for the subscription to TechieGPT has failed.
                </p>
                <p>Please try again by clicking the button below:</p>
                <Link to="/checkout" style={styles.button}>
                    Try Again
                </Link>
            </div>
            <FooterComponent />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f8f9fa',
        color: '#343a40',
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        textAlign: 'center',
    },
    title: {
        color: '#dc3545', // Red for error
    },
    message: {
        margin: '20px 0',
    },
    button: {
        display: 'inline-block',
        padding: '10px 20px',
        color: 'white',
        backgroundColor: '#007bff', // Blue
        textDecoration: 'none',
        borderRadius: '5px',
        transition: 'background-color 0.3s',
    },
};

export default CancelPage;
